
import Api from '../Api'
import { Link as RouterLink } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Fab, ThemeProvider, createTheme, List, ListItem, Icon, IconButton, ListItemText, OutlinedInput, makeStyles, ListItemIcon, SwipeableDrawer } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
import MyDialogQr from '../components/MyDialogQr';
import MyDialogLoading from '../components/MyDialogLoading';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Poppins',
            'sans-serif',
        ].join(','),
    },
});

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    loadingIndicator: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    drawerRoot: {
        width: 275,
    },
    search: {
        height: 50
    },
    fab: {
        position: 'fixed',
        bottom: 10,
        right: 10,
        zIndex: theme.zIndex.modal
    }
}));

const handleLogout = () => {
    Api.logout()
    window.location.href = "/"
}

const RenderLogoutMobile = () => {
    return (<div>
        <ListItem button onClick={handleLogout}>
            <ListItemIcon>
                <i className='fa fa-sign-out-alt' />
            </ListItemIcon>
            <ListItemText>Logout - {Api.getUser()}</ListItemText>
        </ListItem>
    </div>)
}

const RenderLoginMobile = () => {
    if (Api.isNeedLogin()) {
        if (Api.isLoggedIn()) {
            return RenderLogoutMobile()
        } else {
            return (
                <div>
                    <ListItem button onClick={() => { window.location.href = "/login" }}>
                        <ListItemIcon>
                            <i className='fa fa-sign-in-alt' />
                        </ListItemIcon>
                        <ListItemText>Login</ListItemText>
                    </ListItem>
                </div>
            )
        }
    } else {
        return (
            <div></div>
        )
    }
}

const RenderLogoutDesktop = () => {
    if (Api.isLoggedIn()) {
        return <button className="bg-white text-blue-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={handleLogout}>
            Logout - {Api.getUser()}
        </button>
    } else {
        return <div></div>
    }
}

const RenderLoginDesktop = () => {
    return (
        Api.isNeedLogin() && (
            !Api.isLoggedIn() ? (
                <button className="bg-white text-blue-500 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" onClick={() => { window.location.href = "/login" }}>
                    Login
                </button>
            ) : RenderLogoutDesktop()
        )
    )
}

const Layout = (props) => {
    const showBelow = 250
    const classes = useStyles()
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [scrollToTopVisible, setScrollToTopVisible] = useState(false)
    const [daftarToko, setDaftarToko] = useState([])
    const [cari, setCari] = useState(props.cari)
    const [pilihanBarang, setPilihanBarang] = useState([])
    const [open, setOpen] = useState(false);
    const loading = open && pilihanBarang.length === 0;
    const [selectedBarang] = useState(null);
    const [cabang] = useState(Api.getToko()?.id)
    const [daftarFooter, setDaftarFooter] = useState([])
    const [dialogQrVisible, setDialogQrVisible] = useState(false)

    const handleCabangChange = (e) => {
        let id = e.target.value
        let url = Api.getTokoById(id)?.website_url
        window.location.href = url
    }

    const handleScrollToTop = () => {
        window['scrollTo']({ top: 0, behavior: 'smooth' })
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            handleCari()
        }
    }

    const handleScroll = () => {
        if (window.pageYOffset > showBelow) {
            if (!scrollToTopVisible) setScrollToTopVisible(true)
        } else {
            if (scrollToTopVisible) setScrollToTopVisible(false)
        }
    }

    useEffect(() => {
        (async () => {
            let api = await Api.getDaftarFooter(Api.getGudangId())
            if (api.ok) {
                setDaftarFooter(api.data)
            }
        })()
    }, [])

    useEffect(() => {
        setDaftarToko(Api.daftar_toko)
    }, [])

    useEffect(() => {
        if (showBelow) {
            window.addEventListener('scroll', handleScroll)
            return () => {
                window.removeEventListener('scroll', handleScroll)
            }
        }
    })

    useEffect(() => {
        if (!selectedBarang) {
            return undefined
        }
        window.location.href = '/detail-barang/' + selectedBarang.id
    }, [selectedBarang])

    const handleCari = () => {
        let s = cari
        setCari('')
        window.location.href = '/daftar-barang?cari=' + encodeURIComponent((s || ''))
    }

    const handleCamera = () => {
        setDialogQrVisible(true)
    }

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const api = await Api.getDaftarBarang(cari, -1, -1, 0, 60);
            const daftar = api.data.data
            if (active) {
                setPilihanBarang(daftar);
            }
        })();
        return () => {
            active = false;
        };
    }, [cari, loading]);

    useEffect(() => {
        if (!open) {
            setPilihanBarang([]);
        }
    }, [open]);

    const [busy, setBusy] = useState(false);

    const onDetect = (s) => {
        setDialogQrVisible(false)
        setBusy(true)
        Api.getBarangBySku(s).then(itm => {
            window.location.href = '/detail-barang/' + itm.id
            setBusy(false)
        }).catch(er => {
            console.error(er)
            setBusy(false)
            alert(er.message || er)
        })
    }

    return (
        <div>
            <ThemeProvider theme={theme}>
                <MyDialogLoading visible={busy} />
                <MyDialogQr visible={dialogQrVisible} onClose={() => setDialogQrVisible(false)} onDetect={onDetect} />
                <div className={(scrollToTopVisible ? 'block' : 'hidden') + ' fade-in ' + classes.fab}>
                    <Fab size='small' color='primary' onClick={handleScrollToTop}>
                        <Icon className='fa fa-chevron-up' />
                    </Fab>
                </div>
                <SwipeableDrawer anchor={'left'} open={drawerOpen} onOpen={() => { }} onClose={() => { setDrawerOpen(false) }}>
                    <div className={classes.drawerRoot}>
                        <div className='text-center bg-brand'>
                            <div className='text-white p-2'>
                                Amigoo | Online Supplier
                            </div>
                            <div>
                                <img src={Api.getSideImage()} className='w-full' alt='test' />
                            </div>
                        </div>

                        <List>
                            <ListItem button onClick={() => {
                                alert('silakan mendownload Aplikasi di App store')
                            }}>
                                <ListItemIcon>
                                    <i className='fa fa-broadcast-tower' />
                                </ListItemIcon>
                                <ListItemText>Broadcast</ListItemText>
                            </ListItem>
                            <RenderLoginMobile />
                            {daftarToko.map((item) => {
                                return (<ListItem key={item.id} button onClick={() => { window.location.href = item.website_url }}>
                                    <ListItemIcon>
                                        <i className='fa fa-store' />
                                    </ListItemIcon>
                                    <ListItemText primary={item.nama}></ListItemText>
                                </ListItem>)
                            })}
                        </List>
                    </div>
                </SwipeableDrawer>
                <div className='header flex flex-col md:flex-row lg:flex-row 2xl:flex-row bg-brand p-2'>
                    <div className='flex items-center'>
                        <div className='flex-grow-0 block md:hidden lg:hidden xl:hidden 2xl:hidden'>
                            <IconButton onClick={() => { setDrawerOpen(true) }}>
                                <Icon className='fa fa-bars fa-2x text-white' />
                            </IconButton>
                        </div>
                        <div className='logo flex-grow'>
                            <RouterLink to='/'>
                                <img src={Api.getLogo()} width={225} alt='logo amigoo' className='mx-auto' />
                            </RouterLink>
                        </div>
                        <div className='flex-grow-0 block md:hidden lg:hidden xl:hidden 2xl:hidden'>
                            <IconButton component={RouterLink} to='/'>
                                <Icon className='fa fa-home fa-2x text-white'></Icon>
                            </IconButton>
                        </div>
                    </div>
                    <div className='xs:mt-3 sm:mt-3' />
                    <div className='cari pt-2 px-0 md:px-2 lg:px-2 2xl:px-2 w-full'>
                        <div className='w-full flex'>
                            <div className='flex-grow'>
                                <Autocomplete
                                    freeSolo
                                    id="pencarian-barang"
                                    disableClearable
                                    options={pilihanBarang}
                                    style={{ height: 40 }}
                                    inputValue={cari}
                                    onInputChange={(e, value) => { setCari(value) }}
                                    open={open}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    getOptionLabel={(option) => option.nama}
                                    loading={loading}
                                    value={selectedBarang}
                                    onChange={(e, value) => { setCari(value) }}
                                    renderOption={
                                        (option, state) => {
                                            return (
                                                <div className='flex w-full'>
                                                    <a href={"/detail-barang/" + option.id} className="flex w-full">
                                                        <div className='flex-grow'>
                                                            {option.nama}
                                                        </div>
                                                        <div className='flex-grow-0'>
                                                            <img src={Api.getThumb1Url(option.fotoid)} style={{ width: 50 }} alt='test' />
                                                        </div>
                                                    </a>
                                                </div>
                                            )
                                        }
                                    }
                                    renderInput={(params) => (
                                        <div ref={params.InputProps.ref}>
                                            <OutlinedInput
                                                {...params.inputProps}
                                                variant="outlined"
                                                margin='dense'
                                                placeholder='Pencarian ...'
                                                onKeyDown={handleKeyDown}
                                                className="bg-white w-full my-auto"
                                                style={{ height: 40 }}
                                                endAdornment={
                                                    <>
                                                        <IconButton onClick={handleCamera}><Icon className='fa fa-qrcode'></Icon></IconButton>
                                                        <IconButton onClick={handleCari}><Icon className='fa fa-search'></Icon></IconButton>
                                                    </>
                                                }
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="daftar-cabang flex-grow-0 hidden md:block" style={{ width: 300, height: 40 }}>
                                <div className="flex bg-white mx-2 content-center h-full">
                                    <div className='mx-2 flex-grow-0 grid grid-cols-1'>
                                        <i className='fa fa-store place-self-center' />
                                    </div>
                                    <select onChange={handleCabangChange} value={cabang} className=' p-1 h-full flex-grow' >
                                        {daftarToko.map(item => {
                                            return <option key={item.id} value={item.id}>{item.nama}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="login flex-grow-0 hidden md:block" style={{ height: 40 }}>
                                <RenderLoginDesktop />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='page-content'>
                    {
                        props.children
                    }
                    <div className='my-3' />
                    <div className='footer mx-auto max-w-full lg:max-w-7xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3'>
                        {
                            daftarFooter.map((x, idx) => {
                                return (
                                    <div key={idx} dangerouslySetInnerHTML={{
                                        __html: x.isi
                                    }}>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='text-small  text-center mt-2 mb-5'>
                        Copyright Amigoo.id @{new Date().getFullYear()}
                    </div>
                </div>
            </ThemeProvider>
        </div>
    )
}

export default Layout