import { Button, Dialog, useMediaQuery, useTheme } from "@material-ui/core"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import QrReader from "./QrReader.tsx"

const MyDialogQr = ({ visible, onClose, onDetect }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Dialog open={visible} onClose={onClose} fullScreen={fullScreen}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Scan QR
      </DialogTitle>
      <DialogContent>
        <QrReader onDetect={onDetect} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Batal
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MyDialogQr