import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import Api from './Api';
axios.get('/toko.json')
  .then(async(res) => {
    Api.setToko(res.data)
    try {
      let res = await axios.get('/daftar_toko.json')
      Api.daftar_toko = res.data
    } catch (error) {
      Api.daftar_toko = []
    }
  })
  .catch(er => {
    console.error(er)
  })
  .finally(() => {
    ReactDOM.render(
      // <React.StrictMode>
      // </React.StrictMode>,
      <App />,
      document.getElementById('root')
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  })
