import { CircularProgress, Dialog, makeStyles } from "@material-ui/core"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100px', // Adjust as needed
  },
}))

const MyDialogLoading = ({ visible }) => {
  const classes = useStyles()
  return (
    <Dialog open={visible} onClose={() => { }}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Loading
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  )
}

export default MyDialogLoading